@media only screen and (min-width: 768px) {
    .school-logo {
        width: 150px;
        float: right;
    }
}

@media only screen and (max-width: 767px) {
    .school-logo {
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }

    .school-info {
        text-align: center;
    }

    .skills {
        text-align: center;
    }
}

@media only screen and (max-width: 767px) and (orientation: portrait) {
    .school-logo {
        width: 50%;
        margin-left: auto;
        margin-right: auto;
        display: block;
    }

    .school-info {
        text-align: center;
    }
}